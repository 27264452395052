// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/ui/validated-form-password.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/ui/validated-form-password.tsx");
  import.meta.hot.lastModified = "1726716723005.0098";
}
// REMIX HMR END

import { useState } from 'react';
import { useField } from 'remix-validated-form';
import { DangerAlert } from '~/components/icons/alert';
import { EyeOff, EyeOn } from '~/components/icons/eye';
export default function ValidatedFormPassword({
  name,
  placeholder,
  label,
  required = true
}) {
  _s();
  const [isVisible, setVisible] = useState(false);
  const {
    error,
    getInputProps
  } = useField(name);
  const toggle = () => {
    setVisible(!isVisible);
  };
  return <div>
      <label>
        {label}
        {required && <span className="required">*</span>}
      </label>
      <div className="relative">
        <input name={name} {...getInputProps({
        id: name
      })} type={!isVisible ? 'password' : 'text'} placeholder={placeholder} className="w-full !pr-14" />
        <span className="absolute -translate-y-1/2 cursor-pointer eyeIcon right-3 top-1/2" onClick={toggle}>
          {isVisible ? <EyeOn /> : <EyeOff />}
        </span>
      </div>
      {error && <p className="pt-1 error-msg">
          <DangerAlert />
          <span className="pl-2">{error}</span>
        </p>}
    </div>;
}
_s(ValidatedFormPassword, "uugSkGp5oNi+SRRFAJhJ7hqMANs=", false, function () {
  return [useField];
});
_c = ValidatedFormPassword;
var _c;
$RefreshReg$(_c, "ValidatedFormPassword");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;